<template>
    <div class="contact-form" v-if="!loading">
          <form @submit.prevent="onSubmit">
              <template v-if="!success">
              <div class="row">
                  <div class="col-50">
                    <!-- Firstname -->
                    <div class="form-group" :class="{ error: v$.form.firstname.$errors.length }">
                        <input class="form-control" placeholder="Prénom *" type="text" v-model="v$.form.firstname.$model">
                        <!-- error message -->
                        <template v-if="v$.form.firstname.$errors.length > 0">
                            <div class="error-msg" v-if="v$.form.firstname.$errors[0].$validator == 'required'">Ce champs est requis</div>
                            <div class="error-msg" v-if="v$.form.firstname.$errors[0].$validator == 'max'">Ce champs ne doit pas dépasser {{v$.form.firstname.$errors[0].$params.max}} caractères</div>
                        </template>
                    </div> 
                  </div>
                  <div class="col-50">
                    <!-- Lastname -->
                    <div class="form-group" :class="{ error: v$.form.lastname.$errors.length }">
                        <input class="form-control" placeholder="Nom *" type="text" v-model="v$.form.lastname.$model">
                        <!-- error message -->
                        <template v-if="v$.form.lastname.$errors.length > 0">
                            <div class="error-msg" v-if="v$.form.lastname.$errors[0].$validator == 'required'">Ce champs est requis</div>
                            <div class="error-msg" v-if="v$.form.lastname.$errors[0].$validator == 'max'">Ce champs ne doit pas dépasser {{v$.form.lastname.$errors[0].$params.max}} caractères</div>
                        </template>
                    </div>
                  </div>
              </div>

            <div class="row">
                <div class="col-100">
                    <!-- society -->
                    <div class="form-group" :class="{ error: v$.form.society.$errors.length }">
                        <input class="form-control" placeholder="Société" type="text" v-model="v$.form.society.$model">
                        <!-- error message -->
                        <template v-if="v$.form.society.$errors.length > 0">
                            <div class="error-msg" v-if="v$.form.society.$errors[0].$validator == 'max'">Ce champs ne doit pas dépasser {{v$.form.message.$errors[0].$params.max}} caractères</div>
                        </template>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-50">
                    <!-- Job -->
                    <div class="form-group" :class="{ error: v$.form.job.$errors.length }">
                        <input class="form-control" placeholder="Fonction" type="text" v-model="v$.form.job.$model">
                        <!-- error message -->
                        <template v-if="v$.form.job.$errors.length > 0">
                            <div class="error-msg" v-if="v$.form.job.$errors[0].$validator == 'max'">Ce champs ne doit pas dépasser {{v$.form.message.$errors[0].$params.max}} caractères</div>
                        </template>
                    </div>
                </div>
                <div class="col-50">
                    <!-- City -->
                    <div class="form-group" :class="{ error: v$.form.city.$errors.length }">
                        <input class="form-control" placeholder="Ville *" type="text" v-model="v$.form.city.$model">
                        <!-- error message -->
                        <template v-if="v$.form.city.$errors.length > 0">
                            <div class="error-msg" v-if="v$.form.city.$errors[0].$validator == 'required'">Ce champs est requis</div>
                            <div class="error-msg" v-if="v$.form.city.$errors[0].$validator == 'max'">Ce champs ne doit pas dépasser {{v$.form.message.$errors[0].$params.max}} caractères</div>
                        </template>
                    </div>
                </div>
            </div>

              <div class="row">
                  <div class="col-50">
                        <!-- Email -->
                        <div class="form-group" :class="{ error: v$.form.email.$errors.length }">
                            <input class="form-control" placeholder="Email *" type="email" v-model="v$.form.email.$model">
                            <!-- error message -->
                            <template v-if="v$.form.email.$errors.length > 0">                
                                <div class="error-msg" v-if="v$.form.email.$errors[0].$validator == 'required'">Ce champs est requis</div>
                                <div class="error-msg" v-if="v$.form.email.$errors[0].$validator == 'email'">Format d'email invalide</div>
                                <div class="error-msg" v-if="v$.form.email.$errors[0].$validator == 'max'">Ce champs ne doit pas dépasser {{v$.form.email.$errors[0].$params.max}} caractères</div>
                            </template>
                        </div>
                    </div>
                    <div class="col-50">
                        <!-- Phone -->
                        <div class="form-group" :class="{ error: v$.form.phone.$errors.length }">
                            <input class="form-control" placeholder="Téléphone" type="text" v-model="v$.form.phone.$model">
                            <!-- error message -->
                            <template v-if="v$.form.phone.$errors.length > 0">
                                <div class="error-msg" v-if="v$.form.phone.$errors[0].$validator == 'max'">Ce champs ne doit pas dépasser {{v$.form.phone.$errors[0].$params.max}} caractères</div>
                            </template>
                        </div>
                    </div>
                </div>


                <div class="row">
                    <div class="col-100">
                        <!-- Message -->
                        <div class="form-group" :class="{ error: v$.form.message.$errors.length }">
                            <textarea class="form-control" placeholder="Message *" v-model="v$.form.message.$model" rows="5"></textarea>
                            <!-- error message -->
                            <template v-if="v$.form.message.$errors.length > 0">
                                <div class="input-errors" v-for="(error, index) of v$.form.message.$errors" :key="index">
                                    <div class="error-msg" v-if="v$.form.message.$errors[0].$validator == 'required'">Ce champs est requis</div>
                                     <div class="error-msg" v-if="v$.form.message.$errors[0].$validator == 'max'">Ce champs ne doit pas dépasser {{v$.form.message.$errors[0].$params.max}} caractères</div>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-100">
                        <!-- rgpd -->
                        <div class="form-group" :class="{ error: errCheck}">
                            <div class="checkbox-container">
                                <input class="form-control" type="checkbox" v-model="v$.form.rgpd.$model" @click="getChecked(v$.form.rgpd.$model)">
                                <label>En soumettant ce formulaire, j’accepte que les informations saisies soient exploitées par BORN2MINT dans le cadre de ma demande et de la relation commerciale qui peut en découler.</label>
                            </div>
                            <!-- error message -->
                            <template v-if="v$.form.rgpd.$errors.length > 0">
                                <div class="error-msg" v-if="v$.form.message.$errors[0].$validator == 'required'">Ce champs est requis</div>
                            </template>
                        </div>
                    </div>
                </div>
                
                <!-- Submit Button -->
                <div class="row-btn">
                    <button :disabled="v$.form.$invalid" class="btn">Envoyer</button>
                </div>
                </template>
                <!-- Success -->
                <div class="notices success" v-if="success">Votre message a été envoyé avec succès. Nous reviendrons vers vous très prochainement !</div>
                <!-- Errors -->
                <div class="notices errors" v-if="errors">{{ errors}}</div>
          </form>
    </div>
    <div class="loader-container" v-else>
        <Loader ></Loader>
    </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, email, maxLength } from '@vuelidate/validators'
import axios from 'axios'
import Loader from './Loader.vue'

export default {
    name : "ContactForm",
    components : {
        Loader
    },
    setup () {
        return { v$: useVuelidate() }
    },
    data() {
        return {
            form : {
                firstname : "",
                lastname : "",
                email : "",
                phone : "",
                city : "",
                job : "",
                society: "",
                message : "",
                rgpd : false
            },
            success : false,
            errors : null,
            loading : false,
            errCheck : false

        }
    },
    validations() {
        return {
            form : {
                firstname : {
                    required, max: maxLength(150) 
                },   
                lastname : {
                    required, max: maxLength(150) 
                },       
                email: {
                    required, email, max: maxLength(150) 
                },
                phone : {
                    max: maxLength(20) 
                },
                city : {
                    required, max: maxLength(150) 
                },
                job : {
                    max: maxLength(150) 
                },
                society : {
                    max: maxLength(150) 
                },
                message : {
                    required, max: maxLength(1500) 
                },
                rgpd : {
                    required,
                    checked: value => value === true
                }
            }
        }        
    },
    methods : {
        async onSubmit() {

            const isFormCorrect = await this.v$.$validate();

            if (!isFormCorrect) return;

            this.loading = true
            this.errors = null
            this.success = false

            let formData = new FormData();

            formData.append('firstname', this.form.firstname);
            formData.append('lastname', this.form.lastname);
            formData.append('email', this.form.email);
            formData.append('city', this.form.city);
            if(this.form.phone) {
                formData.append('phone', this.form.phone);
            }            
            if(this.form.job) {
                formData.append('job', this.form.job);
            }
            if(this.form.society) {
                formData.append('society', this.form.society);
            }
            if(this.form.message) {
                formData.append('message', this.form.message);
            }

            let config = {
                validateStatus: () => true,
            };
            let vm = this

            axios
                .post('https://born2mint.com/mailer/sendmail.php', formData, config)
                .then(function (res) {
                    vm.loading = false
                    if (res.status == 400) {
                        vm.errors = 'Merci de remplir tous les champs obligatoires.'
                    }else if(res.status == 500) {
                        vm.errors = 'Une erreur est survenue, veuillez réessayer ultérieurement.'
                    
                    }else if(res.status == 200) {
                            vm.success = true
                            vm.form.firstname = null
                            vm.form.lastname = null
                            vm.form.message = null
                            vm.form.phone = null
                            vm.form.email = null                         
                            vm.form.city = null                        
                        }else {
                            vm.errors = 'Une erreur est survenue, veuillez réessayer ultérieurement.'
                        }
                    }
                )     
        },
        getChecked(check) {
            this.errCheck = check;
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/scss/_variables.scss";
    form {
        width:600px;
        max-width:96%;
        margin:0 auto;
        position:relative;
        z-index:1;
    }

    .row {
        display:flex;
        width:100%;
    }

    .col-50 {
        width:47%;
        margin:10px auto;
    }

    .col-100 {
        width:97%;
        margin:10px auto; 
    }

    .form-group {
        display: flex;
        flex-direction: column;
    }


    input, textarea {
        appearance: none;
        padding: 12px 15px;
        border-radius:10px;
        border:1px solid #fff;
        width:calc(100% - 30px);
        transition: 0.5s;
        font-family: 'Open Sans', sans-serif;
    }

    input:focus, textarea:focus {
        outline: none;
        border-color:$mainColor;
    }

    .form-group.error input {
        border-color:$red;
    }

    .error-msg {
        color:$red;
        font-size:12px;
        line-height:1.5em;
    }

    .row-btn {
        display: flex;
        justify-content: center;
        margin: 30px 10px 10px 10px;
    }

    .btn:disabled {
        opacity: 0.5;
        cursor:not-allowed;
    }


    .notices {
        padding:10px;
        width:calc(100% - 20px);
        border-radius:10px;
        text-align:center;
        color:$succes;
        border:1px solid $succes;
        margin-top:20px;
        background: #fff;
    }

    .notices.errors {
        color:$red;
        border-color:$red;
    }

    .loader-container {
        height:200px;
        width:100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    button {
        border: none;
        outline:none;
        cursor: pointer;
    }

    .checkbox-container {
        display:flex;
        align-items: center
    }

    input[type="checkbox"] {
        width:30px;
        min-width: 30px;
        height:30px;
        background:#fff;
        border:2px solid #fff;
        padding:0;
        margin-right:20px;
        cursor: pointer;
    }

    input[type="checkbox"]:checked {
        background:$mainColor;
    }

    .form-group.error input[type="checkbox"] {
        border:2px solid $red;
    }

    label {
        color:#fff;
        font-size:15px;
        font-weight:300;
        font-family: 'Open Sans', sans-serif;
    }

    .error label {
        color:$red;
    }

@media screen and (max-width:768px) { 
    label {
        font-size:13px;
    }

    .row {
        flex-direction: column;
    }

    .col-50 {
        width:97%;
    }

}


</style>