<template>
    <section class="banner">
         <Particles
                id="tsparticles"
                :particlesInit="particlesInit"
                :particlesLoaded="particlesLoaded"
                :options="{
                    background: {
                        color: {
                            value: 'transparent'
                        }
                    },
                    fpsLimit: 120,
                    interactivity: {
                        events: {
                            onClick: {
                                enable: false,
                                mode: 'push'
                            },
                            onHover: {
                                enable: false,
                                mode: 'repulse'
                            },
                            resize: true
                        },
                        modes: {
                            bubble: {
                                distance: 400,
                                duration: 2,
                                opacity: 0.8,
                                size: 40
                            },
                            push: {
                                quantity: 4
                            },
                            repulse: {
                                distance: 200,
                                duration: 0.4
                            }
                        }
                    },
                    particles: {
                        color: {
                            value: ['#3687c8', '#b51f83']                     
                        },
                        links: {
                            color: '#ffffff',
                            distance: 150,
                            enable: false,
                            opacity: 0.5,
                            width: 1
                        },
                        collisions: {
                            enable: true
                        },
                        move: {
                            direction: 'none',
                            enable: true,
                            outModes: {
                                default: 'bounce'
                            },
                            random: false,
                            speed: 0.3,
                            straight: false
                        },
                        number: {
                            density: {
                                enable: true,
                                area: 1200
                            },
                            value: 80
                        },
                        opacity: {
                            value: 0.5
                        },
                        shape: {
                            type: 'circle'
                        },
                        size: {
                            value: 2,
                            random: true,
                            animation: {
                                enable: true,
                                speed: 10,
                                minimumValue: 0.1,
                                sync: false
                            },
                            move: {
                                enable: true,
                            },
                        },
                        animation: {
                            enable: true,
                            speed: 20,
                            minimumValue: 0.1,
                            sync: false
                        },
                    },
                    detectRetina: true
                }"
        />
        <div class="box-container">
            <h1>Contact</h1>
            <contact-form></contact-form>
        </div>
    </section>
</template>

<script>
import ContactForm from "./../components/ContactForm.vue";

export default {
    name : "Contact",
    components : {
        ContactForm
    },
    metaInfo: {
        title: 'Contactez-nous pour échanger sur votre projet NFT et Metaverse',
        meta: [
            { name: 'description', content: 'Born2Mint demeure à votre entière disposition pour échanger sur vos réflexions et projets autour des NFT, du Metaverse et du Web3' },
        ]
    },
}
</script>

<style scoped lang="scss">
@import "@/scss/_variables.scss";

h1 {
  color:#fff;
  font-size:60px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  margin:0;
  letter-spacing: 2px;
  margin-bottom:60px;
  margin-top:100px;
}

.banner {
    min-height: auto;
}


@media screen and (max-width:768px) { 
    h1 {
        font-size:40px;
        margin-top:60px;
    }

}
</style>